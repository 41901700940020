import { Injectable } from '@angular/core';
import { ReplaySubject, Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class TitleNotifierService {

  static title = new ReplaySubject<string>();
}

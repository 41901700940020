import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';

@Component({
  selector: 'app-media-dialog',
  templateUrl: './media-dialog.component.html',
  styleUrls: ['./media-dialog.component.scss'],
})
export class MediaDialogComponent {

  prev_url: SafeUrl;

  constructor(
    @Inject(MAT_DIALOG_DATA) private readonly data: { url: string },
    private sanitizer: DomSanitizer
  ) {
    this.prev_url = this.sanitizer.bypassSecurityTrustUrl(data.url);
  }

}

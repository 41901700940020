import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { DeviceUtilService } from '../utils/device-util.service';

@Injectable({
  providedIn: 'root'
})
export class DevicePlatformResolverService implements Resolve<'web' | 'ios' | 'android'> {

  constructor(
    private readonly deviceUtil: DeviceUtilService
  ) { }

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): 'web' | 'ios' | 'android' | Observable<'web' | 'ios' | 'android'> | Promise<'web' | 'ios' | 'android'> {
    return this.deviceUtil.getPlatform();
  }
}

import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { Component, HostListener, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';
import { PageRenderedEvent } from 'ngx-extended-pdf-viewer';
import { combineLatest, Observable } from 'rxjs';
import { map, shareReplay, tap } from 'rxjs/operators';
import { MediaDialogComponent } from 'src/app/components/dialogs/media-dialog/media-dialog.component';
import { LoadingNotifierService } from 'src/app/services/loading-notifier.service';
import { S3Service } from 'src/app/services/s3.service';
import { StoreService } from 'src/app/services/store.service';
import { TitleNotifierService } from 'src/app/services/title-notifier.service';
import { MessageUtilService } from 'src/app/services/utils/message-util.service';
import { Capacitor } from '@capacitor/core';
import { Entry } from '@awesome-cordova-plugins/file/ngx';
import { StreamingMedia } from '@awesome-cordova-plugins/streaming-media/ngx';

@Component({
  selector: 'app-document',
  templateUrl: './document.component.html',
  styleUrls: ['./document.component.scss']
})
export class DocumentComponent implements OnInit {

  key?: string;
  src?: any;
  device = false;
  sidebarOpen = false;
  find = false;
  entries: Entry[] = [];

  isHandset$: Observable<boolean> = this.breakpointObserver.observe(Breakpoints.Handset)
    .pipe(
      map(result => result.matches),
      shareReplay()
    );

  constructor(
    private readonly route: ActivatedRoute,
    private readonly s3Service: S3Service,
    private readonly dialog: MatDialog,
    private readonly messageUtil: MessageUtilService,
    private readonly breakpointObserver: BreakpointObserver,
    private readonly storeService: StoreService,
    private readonly streamingMedia: StreamingMedia
  ) { }

  async ngOnInit(): Promise<void> {
    combineLatest([this.route.params, this.route.data])
      .pipe(
        tap(async combine => {
          this.key = combine[0].key;
          TitleNotifierService.title.next(this.key);
          LoadingNotifierService.loading.next(true);
          this.device = combine[1].device;
          console.debug(combine);

          if (this.key) {
            if (this.device) {
              try {
                const dir = await this.storeService.getDirectory(this.key);
                await new Promise<void>((res, rej) => {
                  dir.createReader().readEntries(async entries => {
                    console.debug(entries);
                    this.entries = entries.filter(e => e.isFile);
                    const pdfEntry = this.entries.find(e => e.name.endsWith('.pdf'));
                    if (pdfEntry) {
                      try {
                        this.src = Capacitor.convertFileSrc(pdfEntry.nativeURL);
                        res();
                      } catch (error) {
                        rej(error);
                      }
                    } else {
                      rej('pdf file not found in filesystem');
                    }
                  });
                });
              } catch (error) {
                console.error(error);
                // get from remote
                try {
                  this.src = await this.getRemoteUrl();
                } catch (error) {
                  this.handleError(error, MessageUtilService.ERROR.LOAD);
                }
              }
            } else {
              // web mode
              try {
                this.src = await this.getRemoteUrl();
              } catch (error) {
                this.handleError(error, MessageUtilService.ERROR.LOAD);
              }
            }
          } else {
            this.handleError(new Error('invalid key'), MessageUtilService.ERROR.INVALID_KEY);
          }

          if (!this.src) {
            this.handleError('file not found', MessageUtilService.ERROR.FILE_NOT_FOUND);
          }
          LoadingNotifierService.loading.next(false);
        })
      )
      .subscribe();
  }

  afterPageRendered(pageRenderedEvent: PageRenderedEvent) {
    const pageView = pageRenderedEvent.source; /* as PdfPageView */
    const div = pageView.div as HTMLDivElement;
    div.querySelectorAll('a').forEach((a: HTMLAnchorElement) => {
      a.addEventListener('click', (e) => {
        const key = a.href.split('/').pop();
        if (key && !key.startsWith('#')) {
          this.openMedia(key);
        }
        e.preventDefault();
      });
    });
  }

  private openMedia(title: string): void {
    if (this.device) {
      const mediaEntry = this.entries.find(e => e.name === title);
      console.debug(mediaEntry);
      if (mediaEntry) {
        this.streamingMedia.playVideo(mediaEntry.nativeURL, {
          successCallback: () => console.debug('video works'),
          errorCallback: (err: any) => this.handleError(err)
        });
      } else {
        this.getRemoteUrl(title)
          .then(url => this.streamingMedia.playVideo(url, {
            successCallback: () => console.debug('video works'),
            errorCallback: (err: any) => this.handleError(err)
          }))
          .catch(err => this.handleError(err));
      }
    } else {
      this.getRemoteUrl(title)
        .then(url => this.dialog.open(MediaDialogComponent, { data: { url }, minHeight: '50vh', width: '100%' }))
        .catch(err => this.handleError(err));
    }
  }

  private async getRemoteUrl(title?: string): Promise<string> {
    title = title || (await this.s3Service.getKeys(this.key)).find(k => k.title.endsWith('.pdf'))?.title;
    if (!title) {
      throw new Error('Invalid title');
    }
    return [S3Service.cloudfrontUrl, this.key?.replace(' ', '+'), title].join('/');
  }

  private handleError(error: any, msg?: string): void {
    console.error(error);
    this.messageUtil.error(msg || MessageUtilService.ERROR.GENERIC);
  }

  @HostListener('document:keydown', ['$event'])
  private onKeyDown(e: KeyboardEvent): void {
    if (e.key === 'f' && (e.ctrlKey || e.metaKey)) {
      this.find = !this.find;
    }
  }

}

import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { NavigationComponent } from './components/navigation/navigation.component';
import { DeviceGuard } from './guards/device.guard';
import { DocumentComponent } from './pages/document/document.component';
import { DocumentsComponent } from './pages/documents/documents.component';
import { DownloadComponent } from './pages/download/download.component';
import { NfcComponent } from './pages/nfc/nfc.component';
import { DevModeResolverService } from './services/resolvers/dev-mode-resolver.service';
import { DeviceCheckResolverService } from './services/resolvers/device-check-resolver.service';
import { DevicePlatformResolverService } from './services/resolvers/device-platform-resolver.service';

const routes: Routes = [
  {
    path: '',
    component: NavigationComponent,
    children: [
      {
        path: '',
        component: DocumentsComponent,
        resolve: {
          device: DeviceCheckResolverService,
          isDevMode: DevModeResolverService
        }
      },
      {
        path: 'document/:key',
        component: DocumentComponent,
        resolve: {
          device: DeviceCheckResolverService
        }
      },
      {
        path: 'download',
        component: DownloadComponent,
        canActivate: [DeviceGuard]
      },
      {
        path: 'nfc',
        component: NfcComponent,
        canActivate: [DeviceGuard],
        resolve: {
          platform: DevicePlatformResolverService
        }
      }
    ]
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }

import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { DeviceUtilService } from '../services/utils/device-util.service';

@Injectable({
  providedIn: 'root'
})
export class DeviceGuard implements CanActivate {

  constructor(
    private readonly deviceUtil: DeviceUtilService
  ) { }

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    return this.deviceUtil.isRunningOnDevice();
  }

}

import { Component, NgZone } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { TutorialDialogComponent } from './components/dialogs/tutorial-dialog/tutorial-dialog.component';
import { DeviceUtilService } from './services/utils/device-util.service';

const tutorial = 'tutorial';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})
export class AppComponent {

  constructor(
    private readonly ngZone: NgZone,
    private readonly deviceUtilService: DeviceUtilService,
    private readonly dialog: MatDialog
  ) {
    this.ngZone.run(() => {
      // This code will run in Angular's execution context
      if (!localStorage.getItem(tutorial)) {
        this.deviceUtilService.isRunningOnDevice().then(device => this.dialog.open(TutorialDialogComponent, { data: device }));
        localStorage.setItem(tutorial, 'ok');
      }
    });
  }
}

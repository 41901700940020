import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Observable } from 'rxjs';
import { map, shareReplay } from 'rxjs/operators';
import { LoadingNotifierService } from 'src/app/services/loading-notifier.service';
import { TitleNotifierService } from 'src/app/services/title-notifier.service';
import { DeviceUtilService } from 'src/app/services/utils/device-util.service';
import { TutorialDialogComponent } from '../dialogs/tutorial-dialog/tutorial-dialog.component';

@Component({
  selector: 'app-navigation',
  templateUrl: './navigation.component.html',
  styleUrls: ['./navigation.component.scss'],
})
export class NavigationComponent implements OnInit {

  isHandset$: Observable<boolean> = this.breakpointObserver.observe(Breakpoints.Handset)
    .pipe(
      map(result => result.matches),
      shareReplay()
    );
  title?: string;
  loading = false;

  constructor(
    private readonly breakpointObserver: BreakpointObserver,
    private readonly changeDetectorRef: ChangeDetectorRef,
    private readonly deviceUtilService: DeviceUtilService,
    private readonly dialog: MatDialog
  ) { }

  ngOnInit(): void {
    TitleNotifierService.title.subscribe(title => {
      this.title = title;
      this.changeDetectorRef.detectChanges();
    });
    LoadingNotifierService.loading.subscribe(loading => {
      this.loading = loading;
      this.changeDetectorRef.detectChanges();
    });
  }

  async openTutorial(): Promise<void> {
    const device = await this.deviceUtilService.isRunningOnDevice();
    this.dialog.open(TutorialDialogComponent, { data: device });
  }

}

import { Injectable } from '@angular/core';
import PouchDB from 'pouchdb';
import CordovaSqlitePlugin from 'pouchdb-adapter-cordova-sqlite';
import PouchDbFind from 'pouchdb-find';

@Injectable({
  providedIn: 'root'
})
export class PouchdbService {

  private readonly DB_NAME = 'pouchdb.db';
  private db?: PouchDB.Database;

  readonly DB_ERROR = 'Pouchdb is not available!';
  static readonly NFC = 'nfc';

  constructor() { }

  isAvailable(): boolean {
    return !!window['sqlitePlugin' as any];
  }

  async startUp(): Promise<void> {
    if (this.isAvailable()) {
      if (!this.db) {
        PouchDB.plugin(PouchDbFind);
        PouchDB.plugin(CordovaSqlitePlugin);
        this.db = new PouchDB(this.DB_NAME, {
          adapter: 'cordova-sqlite',
          auto_compaction: true,
          location: 'default',
          androidDatabaseImplementation: 2
        });
        return this.startUp();
      } else {
        return new Promise<void>((res, rej) => {
          this.db!.put({ _id: PouchdbService.NFC, tags: [] })
            .then(ok => {
              console.info(`key ${ok.id} created.`);
              res();
            })
            .catch(err => {
              if (err.status === 409) {
                console.info(`key '${PouchdbService.NFC}' already exists.`);
                res();
              } else {
                console.error(err);
                rej();
              }
            });
          console.info(`${this.DB_NAME} initialized.`);
        });
      }
    } else {
      return Promise.reject(this.DB_ERROR);
    }
  }

  async getNfc(): Promise<{ _id: string, _rev: string, tags: string[] }> {
    return !this.db ? Promise.reject(this.DB_ERROR) : this.db.get(PouchdbService.NFC);
  }

  async updateNfcTags(tags: string | string[], restore = false): Promise<void> {
    try {
      const nfc = await this.getNfc();
      this.db?.put({ _id: nfc._id, _rev: nfc._rev, tags: restore ? tags : nfc.tags.concat(tags) });
    } catch (error) {
      return Promise.reject(error);
    }
  }

}

import { Component, NgZone, OnInit } from '@angular/core';
import { NFC, NfcTag } from '@ionic-native/nfc/ngx';
import { TitleNotifierService } from 'src/app/services/title-notifier.service';
import { ActivatedRoute } from '@angular/router';
import { tap } from 'rxjs/operators';
import { PouchdbService } from 'src/app/services/pouchdb.service';
import { MessageUtilService } from 'src/app/services/utils/message-util.service';
import { LoadingNotifierService } from 'src/app/services/loading-notifier.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-nfc',
  templateUrl: './nfc.component.html',
  styleUrls: ['./nfc.component.scss'],
  providers: [NFC]
})
export class NfcComponent implements OnInit {

  tags: string[] = [];
  selected: string[] = [];
  platform?: 'web' | 'ios' | 'android';
  loading = false;
  editing = false;

  constructor(
    private readonly route: ActivatedRoute,
    private readonly nfc: NFC,
    private readonly messageUtil: MessageUtilService,
    private readonly pouchdbService: PouchdbService,
    private _ngZone: NgZone
  ) {
    TitleNotifierService.title.next('NFC');
  }

  ngOnInit(): void {
    this.route.data
      .pipe(tap(data => {
        this.platform = data.platform;
        if (this.platform === 'web') {
          // prevented by the guard 'DeviceGuard'
          console.warn(`NFC features disabled for the ${this.platform} platform`);
        } else {
          this.pouchdbService.startUp()
            .then(() => this.refresh())
            .catch(() => this.messageUtil.error(MessageUtilService.ERROR.NFC));
        }
      }))
      .subscribe();
  }

  readTag(): void {
    console.info(`'${this.platform}' platform detected`);
    let timeout: NodeJS.Timeout;
    try {
      if (this.platform === 'android') {
        // Read NFC Tag - Android
        this.setLoading(true);
        let sub: Subscription;
        this._ngZone.runOutsideAngular(() => {
          const obs = this.nfc.readerMode(this.nfc.FLAG_READER_NFC_A | this.nfc.FLAG_READER_NFC_V);
          sub = obs.subscribe(tag => {
            this._ngZone.run(() => {
              if (timeout) {
                clearTimeout(timeout);
                console.log('tag received. stop timeout');
              }
              this.onTag(tag).then().finally(() => {
                sub?.unsubscribe();
                this.setLoading(false);
              })
            });
          }, err => {
            console.error(err);
            this.setLoading(false);
          });
          this._ngZone.run(() => {
            timeout = setTimeout(() => {
              sub?.unsubscribe();
              this.setLoading(false);
              console.log('stopped by timeout');
            }, 10000);
          });
        });
      } else if (this.platform === 'ios') {
        // Read NFC Tag - iOS
        this.nfc.scanTag()
          .then(tag => this.onTag(tag))
          .then()
          .catch(error => {
            console.error(error);
            this.messageUtil.error(MessageUtilService.ERROR.NFC);
          });
      }
    } catch (error) {
      console.error(error);
      this.messageUtil.error(MessageUtilService.ERROR.NFC);
      if (this.loading) {
        this.setLoading(false);
      }
    }
  }

  async delete(): Promise<void> {
    this.setLoading(true);
    try {
      const tags = this.tags.filter(tag => !this.selected.includes(tag));
      await this.pouchdbService.updateNfcTags(tags, true);
      this.refresh(true);
      this.messageUtil.success(MessageUtilService.SUCCESS.UPDATE);
    } catch (error) {
      console.error(error);
      this.messageUtil.error(MessageUtilService.ERROR.UPDATE);
    }
    this.setLoading(false);
  }

  private refresh(skipLoading = false): void {
    if (!skipLoading) {
      this.setLoading(true);
    }
    // retrieve tags from pouchdb
    this.pouchdbService.getNfc()
      .then(data => {
        console.debug(data);
        this.tags = data.tags;
      })
      .catch(err => {
        console.error(err);
        this.messageUtil.error(MessageUtilService.ERROR.LOAD);
      })
      .finally(() => {
        if (!skipLoading) {
          this.setLoading(false);
        }
      });
  }

  private async onTag(nfcTag: NfcTag): Promise<void> {
    const payload: string[] = nfcTag.ndefMessage?.map(m => this.nfc.bytesToString(m.payload).slice(3)) || [];
    const tags = payload.filter(t => !this.tags.includes(t));
    if (tags.length) {
      console.log('saving tag');
      try {
        await this.pouchdbService.updateNfcTags(tags)
        this.refresh();
        this.messageUtil.success(`${tags}`, 'Tag aggiunto correttamente');
      } catch (error) {
        console.error(error);
        this.messageUtil.error(MessageUtilService.ERROR.UPDATE);
      }
    } else {
      console.log('tag already present');
      this.messageUtil.warn('Tag già presente');
    }
  }

  private setLoading(is: boolean): void {
    this.loading = is;
    LoadingNotifierService.loading.next(is);
  }

}

import { Component, OnInit } from '@angular/core';
import { LoadingNotifierService } from 'src/app/services/loading-notifier.service';
import { StoreService } from 'src/app/services/store.service';
import { TitleNotifierService } from 'src/app/services/title-notifier.service';
import { MessageUtilService } from 'src/app/services/utils/message-util.service';

@Component({
  selector: 'app-download',
  templateUrl: './download.component.html',
  styleUrls: ['./download.component.scss'],
})
export class DownloadComponent implements OnInit {

  titles: string[] = [];
  selection: string[] = [];
  loading = false;
  editing = false;

  constructor(
    private readonly messageUtil: MessageUtilService,
    private readonly storeService: StoreService
  ) {
    TitleNotifierService.title.next('Download');
  }

  async ngOnInit(): Promise<void> {
    console.log('initialiting ....')
    this.setLoading(true);
    this.titles = [];
    try {
      const filesDir = await this.storeService.getOrElseCreateDirectory(StoreService.FILES_PATH);
      await new Promise<void>((res, rej) => {
        filesDir.createReader().readEntries(entries => {
          const names = entries.filter(e => e.isDirectory).map(e => e.name);
          if (!names.length) {
            console.log('data is empty');
            res();
          }
          names.forEach(async name => {
            try {
              const dir = await this.storeService.getDirectory(name);
              dir.createReader().readEntries(fileEntries => {
                console.debug(fileEntries);
                if (fileEntries.length) {
                  this.titles.push(name);
                }
                res();
              }, err => {
                console.error(err);
                rej(err);
              });
            } catch (error) {
              console.error(error);
              rej(error);
            }
          });
        });
      });
      console.log('initialized successfully');
      this.setLoading(false);
    } catch (error) {
      this.setLoading(false);
      console.error(error);
      this.messageUtil.error(MessageUtilService.ERROR.LOAD);
      return Promise.reject(error);
    }
  }

  async delete(): Promise<void> {
    console.warn('delete ' + this.selection);
    this.setLoading(true);
    const promises: Promise<any>[] = [];
    promises.push(...this.selection.map(sel => this.storeService.cleanDirectory(sel)));
    promises.push(...this.selection.map(sel => this.storeService.delete(sel)));
    try {
      await Promise.all(promises);
      this.setLoading(false);
      this.messageUtil.success(MessageUtilService.SUCCESS.UPDATE);
      this.ngOnInit().then().catch(() => this.messageUtil.error(MessageUtilService.ERROR.LOAD));
    } catch (error) {
      console.error(error);
      this.messageUtil.error(MessageUtilService.ERROR.UPDATE);
      this.setLoading(false);
    }
  }

  private setLoading(loading: boolean): void {
    this.loading = loading;
    LoadingNotifierService.loading.next(this.loading);
  }

}

import { Injectable } from '@angular/core';
import { ToastrService } from 'ngx-toastr';

@Injectable({
  providedIn: 'root'
})
export class MessageUtilService {

  static SUCCESS = {
    UPDATE: 'Aggiornamento riuscito',
  }

  static ERROR = {
    GENERIC: 'Errore imprevisto',
    LOAD: 'Caricamento non riuscito',
    UPDATE: 'Aggiornamento non riuscito',
    NFC: 'Errore lettore NFC',
    FILE_NOT_FOUND: 'File non trovato',
    INVALID_KEY: 'Nome del file non valido',
    DOWNLOAD: 'Download non riuscito'
  }

  constructor(
    private readonly toastrService: ToastrService
  ) { }

  success(msg: string, title?: string): void {
    this.toastrService.success(title ? msg : undefined, title || msg);
  }

  error(msg: string, title = 'Errore'): void {
    this.toastrService.error(msg, title);
  }

  warn(msg: string, title = 'Attenzione'): void {
    this.toastrService.warning(msg, title);
  }

  info(msg: string, title?: string): void {
    this.toastrService.info(title ? msg : undefined, title || msg);
  }

}

import { Injectable } from '@angular/core';
import { BehaviorSubject, ReplaySubject, Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class LoadingNotifierService {

  static loading = new BehaviorSubject<boolean>(false);
}

import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { IonicModule } from '@ionic/angular';

import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { MaterialModule } from './modules/material.module';
import { NavigationComponent } from './components/navigation/navigation.component';
import { DocumentsComponent } from './pages/documents/documents.component';
import { MenuComponent } from './components/menu/menu.component';
import { DocumentComponent } from './pages/document/document.component';
import { NgxExtendedPdfViewerModule } from 'ngx-extended-pdf-viewer';
import { MediaDialogComponent } from './components/dialogs/media-dialog/media-dialog.component';
import { FlexLayoutModule } from '@angular/flex-layout';
import { FormsModule } from '@angular/forms';
import { DownloadComponent } from './pages/download/download.component';
import { NfcComponent } from './pages/nfc/nfc.component';
import { EditToolbarComponent } from './components/edit-toolbar/edit-toolbar.component';
import { TutorialDialogComponent } from './components/dialogs/tutorial-dialog/tutorial-dialog.component';
import { ToastrModule } from 'ngx-toastr';
import { File } from '@awesome-cordova-plugins/file/ngx';
import { StreamingMedia } from '@awesome-cordova-plugins/streaming-media/ngx';

@NgModule({
  declarations: [
    AppComponent,
    NavigationComponent,
    DocumentsComponent,
    MenuComponent,
    DocumentComponent,
    MediaDialogComponent,
    DownloadComponent,
    NfcComponent,
    EditToolbarComponent,
    TutorialDialogComponent
  ],
  entryComponents: [],
  imports: [
    BrowserModule,
    IonicModule.forRoot(),
    AppRoutingModule,
    MaterialModule,
    NgxExtendedPdfViewerModule,
    FlexLayoutModule,
    FormsModule,
    ToastrModule.forRoot()
  ],
  providers: [
    File,
    StreamingMedia
  ],
  bootstrap: [
    AppComponent
  ],
})
export class AppModule { }

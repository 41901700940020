import { Injectable } from '@angular/core';
import S3 from 'aws-sdk/clients/s3';
import { environment } from 'src/environments/environment';
import { Key } from '../model/key';
import { ObjectData } from '../model/object-data';

@Injectable({
  providedIn: 'root'
})
export class S3Service {

  private readonly s3 = new S3({
    region: environment.region,
    accessKeyId: environment.key,
    secretAccessKey: environment.secret
  });

  static s3Url: string;
  static cloudfrontUrl = 'https://d3afvfydxi7ll0.cloudfront.net';

  constructor() {
    S3Service.s3Url = 'https://' + [environment.bucketName, 's3', environment.region, 'amazonaws', 'com'].join('.');
  }

  async getKeys(prefix = ''): Promise<Key[]> {
    const list = await this.s3.listObjectsV2({ Bucket: environment.bucketName, Prefix: prefix, Delimiter: '' }).promise();
    const groupBy = <T, K extends keyof any>(list: T[], getKey: (item: T) => K) =>
      list.reduce((previous, currentItem) => {
        const group = (getKey(currentItem));
        if (!previous[group]) previous[group] = [];
        previous[group].push(currentItem);
        return previous;
      }, {} as Record<K, T[]>);
    const grouped = groupBy(list.Contents || [], c => (c.Key as string).split('/')[0]);
    const firstStep = list.Contents?.map(content => ({ parts: content.Key?.split('/') || [] }))
      .filter(c => c && c.parts.includes(prefix));
    const secondStep = firstStep?.map(c => ({ title: !prefix ? c.parts.shift() || '' : c.parts.pop() || '' }))
      .filter(c => c.title && c.title !== '');
    const result = secondStep?.map(c => (
      {
        title: c.title,
        lastModified: grouped[c.title] ? Math.max.apply(Math, grouped[c.title].map(g => (g.LastModified || new Date()).getTime())) : new Date().getTime()
      }
    )) || [];
    return result;
  }

  async getObject(key: string, objectKey: string): Promise<ObjectData> {
    const resp = await fetch(`${S3Service.cloudfrontUrl}/${key}/${objectKey}`);
    const blob = await resp.blob();
    return new ObjectData(key, objectKey, blob, resp.headers.get('content-type')!, +resp.headers.get('content-length')!);
  }
}

import { Injectable } from '@angular/core';
import { Device, DeviceInfo } from '@capacitor/device';
import { Platform } from '@ionic/angular';

@Injectable({
  providedIn: 'root'
})
export class DeviceUtilService {

  constructor(
    private readonly platform: Platform
  ) { }

  async getPlatform(): Promise<'web' | 'ios' | 'android'> {
    return (await Device.getInfo()).platform;
  }

  async isRunningOnDevice(): Promise<boolean> {
    return new Promise<boolean>((res, rej) => {
      this.platform.ready()
        .then(readySource => res(readySource !== 'dom'))
        .catch(err => rej(err))
    });
  }

}

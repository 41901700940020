import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { DeviceUtilService } from '../utils/device-util.service';

@Injectable({
  providedIn: 'root'
})
export class DeviceCheckResolverService implements Resolve<boolean> {

  constructor(
    private readonly deviceUtil: DeviceUtilService
  ) { }

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean | Observable<boolean> | Promise<boolean> {
    return this.deviceUtil.isRunningOnDevice();
  }
}

import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { tap } from 'rxjs/operators';
import { LoadingNotifierService } from 'src/app/services/loading-notifier.service';
import { S3Service } from 'src/app/services/s3.service';
import { TitleNotifierService } from 'src/app/services/title-notifier.service';
import { StoreService } from 'src/app/services/store.service';
import { DirectoryEntry } from '@awesome-cordova-plugins/file/ngx';
import { MessageUtilService } from 'src/app/services/utils/message-util.service';
import { Key } from 'src/app/model/key';

@Component({
  selector: 'app-documents',
  templateUrl: './documents.component.html',
  styleUrls: ['./documents.component.scss'],
})
export class DocumentsComponent implements OnInit {

  keys: Key[] = [];
  progress: { [key: string]: { tot: number, count: number } } = {};
  device = false;

  constructor(
    private readonly route: ActivatedRoute,
    private readonly storeService: StoreService,
    private readonly s3Service: S3Service,
    private readonly messageUtil: MessageUtilService
  ) {
    TitleNotifierService.title.next('Prodotti');
  }

  ngOnInit() {
    this.route.data
      .pipe(
        tap(async data => {
          this.device = data.device;
          LoadingNotifierService.loading.next(true);
          this.init().finally(() => LoadingNotifierService.loading.next(false));
        })
      )
      .subscribe();
  }

  doRefresh(event?: any) {
    console.info('waiting refresh ...')
    LoadingNotifierService.loading.next(true);
    this.init(true)
      .finally(() => {
        event?.target.complete();
        console.info('refresh completed');
        LoadingNotifierService.loading.next(false);
      });
  }

  async download(title: string): Promise<void> {
    if (!this.device) {
      const errMsg = 'App is not running on device!';
      console.warn(errMsg);
      this.messageUtil.error(errMsg);
    }
    console.info('downloading ' + title + ' ...');
    this.progress[title] = { tot: 0, count: 0 };
    console.debug(this.progress[title]);
    try {
      const remoteKeys = await this.getRemoteKeys();
      const remoteKey = remoteKeys.find(k => k.title === title);
      if (!remoteKey) {
        throw new Error('Error getting updates');
      }
      const dir = await this.storeService.getDirectory(title);
      const remoteObjectKeys = await this.s3Service.getKeys(title);
      if (!remoteObjectKeys.length) {
        console.warn('empty data');
        this.messageUtil.warn('Unable to retrieve remote data');
        return;
      }
      const objects = await Promise.all(remoteObjectKeys.map(k => this.s3Service.getObject(title, k.title)));
      this.progress[title].tot = objects.length;
      console.debug(this.progress[title]);
      const saved = await Promise.all(objects.map(o => this.storeService.saveFile(dir.name, o)));
      this.progress[title].count = saved.length;
      console.debug(this.progress[title]);
      this.storeService.store(title, remoteKey);
      console.info('download completed')
    } catch (error) {
      delete this.progress[title];
      this.messageUtil.error(MessageUtilService.ERROR.DOWNLOAD);
    }
  }

  /**
   * Initialize class
   */
  private async init(refresh = false): Promise<void> {
    console.info('initializing ...');
    try {
      if (this.device) {
        // update keys and progress
        if (!refresh) {
          await this.deviceWorkload();
        }
        // complete the update (background)
        const backgroundPromise = this.backgroundWorkload()
          .then(() => this.deviceWorkload())
          .finally(() => {
            this.keys.forEach(k => {
              if (this.progress[k.title]?.tot === 0) {
                delete this.progress[k.title];
              }
            });
            console.info('background workload completed');
          });
        if (refresh) {
          return backgroundPromise;
        }
      } else {
        this.keys = await this.getRemoteKeys();
      }
    } catch (error) {
      console.error(error);
      this.messageUtil.error(MessageUtilService.ERROR.LOAD);
    }
  }

  private async deviceWorkload(): Promise<void> {
    console.info('running device workload ...');
    // update keys
    const filesDirEntries = await new Promise<DirectoryEntry[]>((res, rej) => {
      this.storeService.getOrElseCreateDirectory(StoreService.FILES_PATH)
        .then((filesDir: DirectoryEntry) => filesDir.createReader().readEntries(
          filesDirEntries => res((filesDirEntries.filter(e => e.isDirectory)) as DirectoryEntry[]),
          err => rej(err)
        ))
        .catch(err => rej(err));
    });
    const keyPromises: Promise<Key>[] = [];
    const promises: Promise<void>[] = [];
    const progress: { [key: string]: { tot: number, count: number } } = {};

    filesDirEntries.forEach(e => {
      promises.push(
        new Promise<void>((res, rej) => {
          e.createReader().readEntries(
            dirEntries => {
              const dirFilesCount = dirEntries.filter(de => de.isFile).length;
              keyPromises.push(this.storeService.getOrElseCreate(e.name));
              progress[e.name] = { tot: dirFilesCount, count: dirFilesCount };
              res();
            },
            err => {
              console.error(err);
              rej(err);
            }
          );
        })
      );
    });
    await Promise.all(promises);

    this.keys = await Promise.all(keyPromises);
    console.debug('local keys: ' + JSON.stringify(this.keys));

    // update progress
    this.progress = progress;
    console.debug('progress: ' + JSON.stringify(this.progress));
  }

  /**
   * Execute background workload
   */
  private async backgroundWorkload(): Promise<void> {
    console.info('running background workload ...');
    try {
      // get remote keys
      const remoteKeys = await this.getRemoteKeys();
      console.debug('remote keys: ' + JSON.stringify(remoteKeys));

      // check remote with local keys
      const toRemove = this.keys.filter(k => !remoteKeys.find(rk => rk.title === k.title));
      const toSave = remoteKeys.filter(rk => !this.keys.find(k => k.title === rk.title));
      const upToDateKeys: Key[] = [];

      this.keys.forEach(k => {
        const remoteKeyMatch = remoteKeys.find(rk => rk.title === k.title);
        if (remoteKeyMatch && k.lastModified < remoteKeyMatch.lastModified) {
          upToDateKeys.push(remoteKeyMatch);
        }
      });

      // update directories and local keys
      const promises: Promise<any>[] = [];
      toRemove.forEach(k => {
        promises.push(this.storeService.cleanDirectory(k.title));
        promises.push(this.storeService.delete(k.title));
      });
      toSave.forEach(k => {
        promises.push(this.storeService.createDirectory(k.title));
        promises.push(this.storeService.store(k.title, k));
      });

      await Promise.all(promises);
      if (upToDateKeys.length) {
        await this.cleanup(upToDateKeys);
        upToDateKeys.forEach(k => this.messageUtil.info(`"${k.title}" aggiornato`));
      }
    } catch (error) {
      console.error(error);
    }
  }

  /**
   * cleanup directories
   * @param keys the up to date keys
   */
  private async cleanup(keys: Key[]): Promise<void> {
    for (let k of keys) {
      await this.storeService.cleanDirectory(k.title);
      await this.storeService.createDirectory(k.title);
      await this.storeService.store(k.title, k);
    }
  }

  /**
   * Get remote keys
   * 
   * @param prefix the prefix
   * @returns promise of remote Key array
   */
  private getRemoteKeys(prefix?: string): Promise<Key[]> {
    console.info('getting from remote ... ' + (prefix || '/'));
    return this.s3Service.getKeys(prefix);
  }

}

import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-edit-toolbar',
  templateUrl: './edit-toolbar.component.html',
  styleUrls: ['./edit-toolbar.component.scss'],
})
export class EditToolbarComponent implements OnInit {

  @Input() data: any[] = [];
  @Input() selected: any[] = [];
  @Input() disabled?: boolean;

  @Output() deleteEvent = new EventEmitter<void>();
  @Output() editEvent = new EventEmitter<boolean>();

  editing = false;

  constructor() { }

  ngOnInit() { }

  delete(): void {
    this.deleteEvent.next();
    this.edit();
  }

  edit(): void {
    this.editing = !this.editing;
    this.editEvent.next(this.editing);
  }

}

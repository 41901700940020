import { Attachment } from "./attachment";

export class ObjectData {
    folder: string;
    title: string;
    content: Blob;
    type: string;
    size: number

    constructor(folder: string, title: string, content: Blob, type: string, size: number) {
        this.folder = folder;
        this.title = title;
        this.content = content;
        this.type = type;
        this.size = size;
    }

    toAttachment(): Attachment {
        return { type: this.type, title: this.title, data: this.content };
    }
}

import { Component, EventEmitter, Output } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Params } from '@angular/router';
import { PouchdbService } from 'src/app/services/pouchdb.service';
import { DeviceUtilService } from 'src/app/services/utils/device-util.service';
import { TutorialDialogComponent } from '../dialogs/tutorial-dialog/tutorial-dialog.component';

@Component({
  selector: 'app-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.scss'],
})
export class MenuComponent {

  @Output() closeEvent = new EventEmitter<void>();

  readonly pages: { title: string, icon: string, url: string, queryParams?: Params }[] = [
    { title: 'Prodotti', icon: 'folder_open', url: '/' }
  ];

  constructor(
    private readonly pouchdbService: PouchdbService,
    private readonly dialog: MatDialog,
    private readonly deviceUtilService: DeviceUtilService
  ) {
    if (this.pouchdbService.isAvailable()) {
      this.pages.push({ title: 'Download', icon: 'system_security_update_good', url: '/download' });
      this.pages.push({ title: 'Nfc', icon: 'nfc', url: '/nfc' });
    }
  }

  close(): void {
    this.closeEvent.next();
  }

  async openTutorial(): Promise<void> {
    const device = await this.deviceUtilService.isRunningOnDevice();
    this.dialog.open(TutorialDialogComponent, { data: device });
  }

}

import { Injectable } from '@angular/core';
import { DirectoryEntry, File, FileEntry, RemoveResult } from '@awesome-cordova-plugins/file/ngx';
import { ObjectData } from '../model/object-data';
import { StorageMap } from '@ngx-pwa/local-storage';
import { Key } from '../model/key';

@Injectable({
  providedIn: 'root'
})
export class StoreService {

  static readonly FILES_PATH = 'files';
  static readonly NFC_PATH = 'nfc';

  constructor(
    private readonly file: File,
    private readonly storage: StorageMap
  ) { }

  async checkDirectory(path: string): Promise<boolean> {
    try {
      return this.file.checkDir(this.file.dataDirectory, path);
    } catch (error) {
      console.warn(`directory ${path} not exists!`);
      return false;
    }
  }

  async getOrElseCreateDirectory(path: string): Promise<DirectoryEntry> {
    try {
      return await this.getDirectory(path)
    } catch (error) {
      console.warn("'" + path + "' directory not exists", error);
      return this.createDirectory(path);
    }
  }

  async createDirectory(path: string): Promise<DirectoryEntry> {
    return this.file.createDir(this.file.dataDirectory, path === StoreService.FILES_PATH ? path : (StoreService.FILES_PATH + '/' + path), true);
  }

  async getDirectory(path: string): Promise<DirectoryEntry> {
    const fullPath = this.getFullPath(path);
    return this.file.resolveDirectoryUrl(fullPath);
  }

  async saveFile(path: string, object: ObjectData): Promise<FileEntry> {
    try {
      const fullPath = this.getFullPath(path);
      return this.file.writeFile(fullPath, object.title, object.content, { replace: true });
    } catch (error) {
      return Promise.reject(error);
    }
  }

  async cleanDirectory(path: string): Promise<RemoveResult> {
    console.debug(`cleaning ${this.getFullPath(path)} ...`);
    return this.file.removeRecursively(this.file.dataDirectory, path === StoreService.FILES_PATH ? path : (StoreService.FILES_PATH + '/' + path));
  }

  private getFullPath(path: string): string {
    if (!this.file.dataDirectory) {
      throw new Error('data directory is not ready');
    }
    const fullPath = this.file.dataDirectory + (path === StoreService.FILES_PATH ? path : (StoreService.FILES_PATH + '/' + path));
    console.debug(fullPath);
    return fullPath;
  }

  async store(key: string, value: Key): Promise<Key> {
    console.debug(`storing '${key}' key ...`);
    await this.storage.set(key, value).toPromise();
    return value;
  }

  delete(key: string): Promise<void> {
    return this.storage.delete(key).toPromise();
  }

  async get(key: string): Promise<Key> {
    const obj = await this.storage.get(key).toPromise();
    if (!obj) {
      throw new Error(`${key} key not found`);
    }
    return obj as Key;
  }

  async getOrElseCreate(title: string): Promise<Key> {
    let obj = await this.storage.get(title).toPromise();
    if (!obj) {
      console.warn(`${title} key not found`);
      const key: Key = { title: title, lastModified: 0 };
      obj = await this.store(title, key);
    }
    return obj as Key;
  }
}
